import i18n from "../plugins/i18n";

let staticData = {
  statisticsPeriods: [
    { name: i18n.t("gbl-periods-lastyear"), id: 0 },
    { name: i18n.t("gbl-periods-since1year"), id: 1 },
    { name: i18n.t("gbl-periods-thisyear"), id: 2 },
    { name: i18n.t("gbl-periods-last6months"), id: 3 },
    { name: i18n.t("gbl-periods-last90days"), id: 4 },
    { name: i18n.t("gbl-periods-last60days"), id: 5 },
    { name: i18n.t("gbl-periods-last30days"), id: 6 },
    { name: i18n.t("gbl-periods-lastmonth"), id: 7 },
    { name: i18n.t("gbl-periods-thismonth"), id: 8 },
    { name: i18n.t("gbl-periods-today"), id: 9 },
    { name: i18n.t("gbl-periods-yesterday"), id: 10 },
    { name: i18n.t("gbl-periods-24h"), id: 11 },
    { name: i18n.t("gbl-periods-48h"), id: 12 },
    { name: i18n.t("gbl-periods-date"), id: 13 },
    { name: i18n.t("gbl-periods-all"), id: 14 }
  ],

  ordersStates: [
    { name: i18n.t("order-state-pending"), id: 0 },
    { name: i18n.t("order-state-processing"), id: 1 },
    { name: i18n.t("order-state-shipped"), id: 2 },
    { name: i18n.t("order-state-completed"), id: 3 }
  ],

  ticketsStates: [
    { name: i18n.t("ticket-state-submitted"), id: 0 },
    { name: i18n.t("ticket-state-processing"), id: 1 },
    { name: i18n.t("ticket-state-close"), id: 2 }
  ],

  invoicesPaymentsStates: [
    { name: i18n.t("invoice-payment-state-pending"), id: 1 },
    { name: i18n.t("invoice-payment-state-partial"), id: 2 },
    { name: i18n.t("invoice-payment-state-payed"), id: 3 },
    { name: i18n.t("invoice-payment-state-void"), id: 4 }
  ],

  ordersPaymentStates: [
    { name: i18n.t("order-payment-state-pending"), id: 0 },
    { name: i18n.t("order-payment-state-waiting-wire"), id: 1 },
    { name: i18n.t("order-payment-state-completed-cash"), id: 2 },
    { name: i18n.t("order-payment-state-completed-check"), id: 3 },
    { name: i18n.t("order-payment-state-completed-wire"), id: 4 },
    { name: i18n.t("order-payment-state-void"), id: 5 },
    { name: i18n.t("order-payment-state-partial"), id: 6 },
    { name: i18n.t("order-payment-state-completed-pos"), id: 7 },
    { name: i18n.t("order-payment-state-completed-loss"), id: 8 }
  ],

  days: [
    { name: i18n.t("day-monday"), id: 1 },
    { name: i18n.t("day-tuesdays"), id: 2 },
    { name: i18n.t("day-wednesday"), id: 3 },
    { name: i18n.t("day-thursday"), id: 4 },
    { name: i18n.t("day-friday"), id: 5 },
    { name: i18n.t("day-saturday"), id: 6 },
    { name: i18n.t("day-sunday"), id: 7 }
  ],

  payment_modes: [
    { name: i18n.t("customer-editor-hint-payment-mode-cash"), id: 1 },
    { name: i18n.t("customer-editor-hint-payment-mode-wire"), id: 2 },
    { name: i18n.t("customer-editor-hint-payment-mode-pos"), id: 3 }
  ],

  availability: [
    { name: i18n.t("availability-immediate"), id: 0 },
    { name: i18n.t("availability-24-hours"), id: 1 },
    { name: i18n.t("availability-48-hours"), id: 2 },
    { name: i18n.t("availability-72-plus-hours"), id: 3 }
  ],

  last_time: [
    { name: i18n.t("gbl-none-male"), id: 0 },
    { name: "01:00", id: 1 },
    { name: "02:00", id: 2 },
    { name: "03:00", id: 3 },
    { name: "04:00", id: 4 },
    { name: "05:00", id: 5 },
    { name: "06:00", id: 6 },
    { name: "07:00", id: 7 },
    { name: "08:00", id: 8 },
    { name: "09:00", id: 9 },
    { name: "10:00", id: 10 },
    { name: "11:00", id: 11 },
    { name: "12:00", id: 12 },
    { name: "13:00", id: 13 },
    { name: "14:00", id: 14 },
    { name: "15:00", id: 15 },
    { name: "16:00", id: 16 },
    { name: "17:00", id: 17 },
    { name: "18:00", id: 18 },
    { name: "19:00", id: 19 },
    { name: "20:00", id: 20 },
    { name: "21:00", id: 21 },
    { name: "22:00", id: 22 },
    { name: "23:00", id: 23 }
  ],

  product_availability: [
    { name: i18n.t("availability-by-category"), id: 0 },
    { name: i18n.t("availability-24-hours"), id: 1 },
    { name: i18n.t("availability-48-hours"), id: 2 },
    { name: i18n.t("availability-72-plus-hours"), id: 3 }
  ],

  product_last_time: [
    { name: i18n.t("last-time-by-category"), id: 0 },
    { name: "01:00", id: 1 },
    { name: "02:00", id: 2 },
    { name: "03:00", id: 3 },
    { name: "04:00", id: 4 },
    { name: "05:00", id: 5 },
    { name: "06:00", id: 6 },
    { name: "07:00", id: 7 },
    { name: "08:00", id: 8 },
    { name: "09:00", id: 9 },
    { name: "10:00", id: 10 },
    { name: "11:00", id: 11 },
    { name: "12:00", id: 12 },
    { name: "13:00", id: 13 },
    { name: "14:00", id: 14 },
    { name: "15:00", id: 15 },
    { name: "16:00", id: 16 },
    { name: "17:00", id: 17 },
    { name: "18:00", id: 18 },
    { name: "19:00", id: 19 },
    { name: "20:00", id: 20 },
    { name: "21:00", id: 21 },
    { name: "22:00", id: 22 },
    { name: "23:00", id: 23 }
  ],

  dataTableFooterOptions: {
    showFirstLastPage: true,
    "items-per-page-options": [5, 10, 20, 50, 100, 200, -1]
  },

  defaultPerPage: 50
};

export default staticData;
